
import logo from '../../assets/img/logo.png';


import axios from 'axios';
import { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import '../../assets/css/team.css';

import { useEffect } from 'react';


// import 'slick-carousel/slick/slick.css';
// import 'slick-carousel/slick/slick-theme.css';
// import Pagination from './react-pegination';
import Header from '../Common/Header';
import Footer from '../Common/Footer';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';




function My_downline() {
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { level } = useParams(); // Get the level from URL params

  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const apiImageUrl = process.env.REACT_APP_IMAGE_BASE_URL;
  const [recMetrics, setRecMetrics] = useState([0, 0, 0]);

  const reports = []; // Your report data
  const perPage = 10; // Number of reports per page

  // Pagination
  const [pageNumber, setPageNumber] = useState(0);
  const pageCount = Math.ceil(reports.length / perPage);

  const handlePageClick = ({ selected }) => {
    setPageNumber(selected);
  };

  const offset = pageNumber * perPage;
  const currentPageReports = reports.slice(offset, offset + perPage);

  useEffect(() => {
    fetchData();
  }, [pageNumber]);

  const fetchData = async () => {
    try {
      const id = localStorage.getItem('walletAddress');
      const response = await axios.get(`${apiBaseUrl}/downline/${id}/${level}`);
      // console.log(response.data);
      if(response.data){
      setData(response.data);
    }else{
      navigate('/register');
      toast.error('User not register')
    }
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const [modalMessage3, setModalMessage] = useState(null);
  useEffect(() => {
    const storedMessage = localStorage.getItem('modalMessage3');
    if (storedMessage) {
      setModalMessage(JSON.parse(storedMessage));
      localStorage.removeItem('modalMessage3');
    }

    fetchData();
  }, []);

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const formatDate = (dateString) => {
    const months = [
      'Jan', 'Feb', 'March', 'April', 'May', 'June',
      'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec',
    ];

    const date = new Date(dateString);
    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();

    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');

    return `${day} ${month} ${year} | ${hours}:${minutes}:${seconds}`;
  };

  return (
    <body>
      <div>
        <Header />

        <section style={{marginTop:'65px'}}>
          <div>
            <div className="team-body-content">
              <center>
                <h3 className='my-3 network_heading'>Level {level} Network</h3>
              </center>
              {data ? (
                <section className="mt-2">
                  {data.map((user, index) => (
                    <div key={index} className="col-12">
                      <div className="team-transaction-box shadow">
                        <a href="#" style={{ textDecoration: 'none' }}>
                          <div>
                            <div
                              style={{
                                color: 'black',
                                width: '100px',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                              }}
                            >
                              {user.first_name}
                            </div>

                            <div className="team-transaction-name-2 mb-2">
                              {user.user_id}
                            </div>
                          </div>
                          <div className="d-flex justify-content-between">
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              <span className="report-date" style={{fontSize:'10px'}}>{formatDate(user.joining_date)}</span>
                              <br />
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              <h6 className='' style={{color:'#0d6efd'}}>
                                ${user.user_business}

                              </h6>
                            </div>
                          </div>
                        </a>
                      </div>
                    </div>
                  ))}
                </section>
              ) : (
                <div style={{ textAlign: 'center' }}>No Team data available</div>
              )}
            </div>
          </div>
        </section>
        <ToastContainer />
        <Footer />
      </div>
    </body>
  );
}

export default My_downline;
