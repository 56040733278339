import axios from 'axios';
import React, { useState, useEffect, useContext } from 'react';
import Header from '../Common/Header';
import Footer from '../Common/Footer';
import { useNavigate, Link } from 'react-router-dom';

import LevelIncomeImg from '../../assets/img/level_income_img.png';
import DirectIncomeImg from '../../assets/img/direct_income_img.png';
import RewardIncomeImg from '../../assets/img/reward_income_img.png';
import StackingIncomeImg from '../../assets/img/stscking_income_img.png';
import RedeemBonusImg from '../../assets/img/Redeem_bonus_img.png';
import AvailableForWithdrawalImg from '../../assets/img/available_for_withdrawal.png';

import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../assets/css/Dashboard.css'; // Custom CSS
import { WalletContext } from '../Common/WalletContext';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Dashboard = () => {
    const {walletAddress, balance, isConnecting, phirate, connectWallet, disconnectWallet } = useContext(WalletContext);
    const [homepageData, setHomepageData] = useState(null);
    const [availableWithdrawalIncome, setAvailableWithdrawalIncome] = useState(0);
    const [totalEarningWalletIncome, setTotalEarningwallet] = useState(0);
    const [redeembonus, setRedeembonus] = useState(false);
    const [redeembonus1, setRedeembonus1] = useState(false);
    const [cryptoData, setCryptoData] = useState([]);
    // const walletAddress = 'uk';
    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
    const navigate = useNavigate();
    // Fetch homepage data based on userID
    const checkWalletAndFetchData = async (walletAddress) => {
        if (!walletAddress) {
            navigate(-1);
            toast.error('Connect Your Wallet');
            return; // Return early if walletAddress is not available.
        }

        try {
            const response = await axios.get(`${apiBaseUrl}/getuser/${walletAddress}`);
            const userData = response.data;
            const userID = userData?.id;
            if (!userID) {
                toast.error('User not fount.')
                navigate('/register'); // Navigate if userID is not found
            } else {

                localStorage.setItem('user_ID', userID);
            }
        } catch (error) {
            console.log(error)
            // toast.error(error.response.data.message);
        }
    };

    const toggalreddempopup = () => {
        setRedeembonus(!redeembonus);
    }

    const toggalreddempopup1 = () => {
        setRedeembonus1(!redeembonus);
    }

    const closepopup = () => {
        setRedeembonus(false);
        setRedeembonus1(false);
    }



    const fetchHomepageData = async () => {
        try {
            const response = await axios.get(`${apiBaseUrl}/homepageapi/${walletAddress}`);
            setHomepageData(response.data);
            setAvailableWithdrawalIncome(parseFloat(response.data?.wallet?.available_withdraw || 0).toFixed(9));
            setTotalEarningwallet(parseFloat(response.data?.wallet?.total_earning_wallet || 0).toFixed(9));
        } catch (error) {
            // toast.error(error.response.data.message);
            console.log(error);
        }
    };
   


    // Function to periodically update availableWithdrawalIncome
    const updateAvailableWithdrawalIncome = async () => {
        try {
            const response = await axios.get(`${apiBaseUrl}/homepageapi/${walletAddress}`);
            setAvailableWithdrawalIncome(parseFloat(response.data?.wallet?.available_withdraw || 0).toFixed(9));
            setTotalEarningwallet(parseFloat(response.data?.wallet?.total_earning_wallet || 0).toFixed(9));
        } catch (error) {
            // toast.error(error.message);
            console.log(error);
        }
    };

    useEffect(() => {
        if (walletAddress) {
            checkWalletAndFetchData(walletAddress);
            fetchHomepageData(); // Initial fetch of homepage data

            // Set up an interval to update availableWithdrawalIncome every 3 seconds
            const intervalId = setInterval(updateAvailableWithdrawalIncome, 5000);

            // Clean up interval on component unmount
            return () => clearInterval(intervalId);
        }
    }, [walletAddress]);

    //value variable decleare
    const totalDeposit = parseFloat(homepageData?.wallet?.total_deposit || 0).toFixed(2);
    const totalWithdrawal = parseFloat(homepageData?.wallet?.total_withdrawal || 0).toFixed(2);
    const levelIncome = parseFloat(homepageData?.wallet?.level_income || 0).toFixed(2);
    const directIncome = parseFloat(homepageData?.wallet?.direct_income || 0).toFixed(2);
    const rewardIncome = parseFloat(homepageData?.wallet?.reward_income || 0).toFixed(2);
    const redeemIncome = Math.floor(Number(homepageData?.wallet?.redeem_income) * 100000) / 100000;    
    // const availableWithdrawalIncome = parseFloat(homepageData?.wallet?.available_withdraw || 0).toFixed(6);
    const stackingProfitIncome = parseFloat(homepageData?.wallet?.staking_profit || 0).toFixed(2);
    const earningWalletIncome = parseFloat(homepageData?.wallet?.earning_wallet || 0).toFixed(2);
    // const totalEarningWalletIncome = parseFloat(homepageData?.wallet?.total_earning_wallet || 0).toFixed(2);
    const stackingBonusIncome = parseFloat(homepageData?.wallet?.staking_bonus || 0).toFixed(2);
    const stackingBonusIncometo = parseFloat(homepageData?.wallet?.staking_bonus || 0);
    const teamstakingbusiness = parseFloat(homepageData?.user.my_business || 0).toFixed(2);


    const handlestaketransfer = async () => {
        console.log(stackingBonusIncometo);
        const formDataToSend = {
            amount: stackingBonusIncometo,
            wallet_address: walletAddress,
            transfer_from: 'team_staking',
        };
        if (stackingBonusIncome <= 0) {
            toast.error('Transfer Amount should be greater than 0');
            return;
        }
        try {
            const response = await axios.post(
                `${apiBaseUrl}/transferToWallet`,
                formDataToSend,
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            );

            // Handle the success response
            toast.success('Team Staking Bonus Transfered Successfully');
            setRedeembonus(false)
            fetchHomepageData();
        } catch (error) {
            // Handle any errors that occur during the API request
            // console.log(error);
            toast.error(error.response.data.message);

        }
    }

    const handleredeemtransfer = async () => {
        const formDataToSend = {
            amount: redeemIncome,
            wallet_address: walletAddress,
            transfer_from: 'redeem_bonus',
        };

        if (redeemIncome <= 0) {
            toast.error('Transfer Amount should be greater than 0');
            return;
        }
        try {
            const response = await axios.post(
                `${apiBaseUrl}/transferToWallet`,
                formDataToSend,
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            );

            // Handle the success response
            toast.success('Team Redeem Bonus Transfered Successfully');
            setRedeembonus1(false)
            fetchHomepageData();

        } catch (error) {
            // Handle any errors that occur during the API request

            toast.error(error.response.data.message);

        }
    }

    return (
        <>
            <Header />
            <div className='dashboard_marque' style={{ marginTop: '65px' }}>
                {/* <h1>Top 10 Cryptocurrencies (USDT)</h1> */}
                <marquee behavior="scroll" direction="left">
                    <span>
                        {homepageData && homepageData.setting[0] && homepageData.setting[0].marquee}
                    </span>

                </marquee>
            </div>
            <div className='' style={{marginBottom:'70px'}}>
                <div className="row mt-3 dashboard_row">
                    <div className="col-6 col-md-6 mb-3 dashboard_col_4">
                        <div className="portfolio-income-card">
                            <img src={StackingIncomeImg} alt="Matching Income" className="portfolio-icon" />
                            <div className="dashboard_heading">MY STAKING</div>
                            <p>${totalDeposit}</p>
                            <div style={{ display: 'flex', justifyContent: "center", marginTop: '9px' }}>
                                <Link to='/deposit_report'>
                                    <div className="text-center	fas fa-angle-double-right" style={{ color: '#009dff', border: '1px solid #c2c0c0', borderRadius: '25px', padding: '3px' }}></div>
                                </Link>
                            </div>

                        </div>
                    </div>
                    <div className="col-6 col-md-6 mb-3 dashboard_col_4">
                        <div className="portfolio-income-card">
                            <img src={LevelIncomeImg} alt="Direct Income" className="portfolio-icon" />
                            <div className="dashboard_heading">TEAM STAKING</div>
                            <p>${teamstakingbusiness}</p>
                            <div style={{ display: 'flex', justifyContent: "center", marginTop: '9px' }}>
                                <Link to='/level_show'>
                                    <div className="text-center	fas fa-angle-double-right" style={{ color: '#009dff', border: '1px solid #c2c0c0', borderRadius: '25px', padding: '3px' }}></div>
                                </Link>
                            </div>

                        </div>
                    </div>
                    <div className="col-12 col-md-12 mb-3 dashboard_col_4">
                        <div className="portfolio-income-card" style={{ textAlign: 'left' }}>
                            <div className='d-flex justify-content-evenly'>
                                <img src={LevelIncomeImg} alt="Direct Income" className="portfolio-icon" style={{ width: '50px', height: '50px' }} />
                                <div className='' style={{textAlign:'center',alignContent:'center'}}>
                                    <div className="dashboard_heading">EARNING PACKAGE WALLET</div>
                                    <p className='mb-0' style={{fontSize:'20px' }}>${totalEarningWalletIncome}</p>
                                </div>
                            </div>
                            <div className='d-flex justify-content-end' style={{ marginRight: '9px' }}>

                                <div style={{ marginTop: '5px' }}>
                                    <Link to='#'>
                                        <div className="text-center	fas fa-angle-double-right" style={{ color: '#c2c0c0', border: '1px solid #c2c0c0', borderRadius: '25px', padding: '3px' }}></div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* <div className="col-6 col-md-6 mb-3 dashboard_col_4">
                        <div className="portfolio-income-card">
                            <img src={RewardIncomeImg} alt="Matching Income" className="portfolio-icon" />
                            <div className="dashboard_heading">EARNING WALLET</div>
                            <p>${totalEarningWalletIncome}</p>
                            <div style={{ display: 'flex', justifyContent: "center", marginTop: '9px' }}>
                                <Link to='#'>
                                    <div className="text-center	fas fa-angle-double-right" style={{ color: '#c2c0c0', border: '1px solid #c2c0c0', borderRadius: '25px', padding: '3px' }}></div>
                                </Link>
                            </div>

                        </div>
                    </div> */}

                </div>

                <div className="row dashboard_row">
                    <div className="col-6 col-md-6 mb-3 dashboard_col_4">
                        <div className="portfolio-income-card">
                            <img src={StackingIncomeImg} alt="Matching Income" className="portfolio-icon" />
                            <div className="dashboard_heading">STAKING PROFIT</div>
                            <p>${stackingProfitIncome}</p>
                            <div style={{ display: 'flex', justifyContent: "center", marginTop: '9px' }}>
                                <Link to='#'>
                                    <div className="text-center	fas fa-angle-double-right" style={{ color: '#c2c0c0', border: '1px solid #c2c0c0', borderRadius: '25px', padding: '3px' }}></div>
                                </Link>
                            </div>

                        </div>
                    </div>
                    <div className="col-6 col-md-6 mb-3 dashboard_col_4">
                        <div className="portfolio-income-card">
                            <img src={DirectIncomeImg} alt="Level Income" className="portfolio-icon" />
                            <div className="dashboard_heading">DIRECT INCOME</div>
                            <p>${directIncome}</p>
                            <div style={{ display: 'flex', justifyContent: "center", marginTop: '9px' }}>
                                <Link to='/direct_report'>
                                    <div className="text-center	fas fa-angle-double-right" style={{ color: '#009dff', border: '1px solid #c2c0c0', borderRadius: '25px', padding: '3px' }}></div>
                                </Link>
                            </div>

                        </div>
                    </div>
                    <div className="col-6 col-md-6 mb-3 dashboard_col_4">
                        <div className="portfolio-income-card">
                            <img src={LevelIncomeImg} alt="Direct Income" className="portfolio-icon" />
                            <div className="dashboard_heading">LEVEL INCOME</div>
                            <p>${levelIncome}</p>
                            <div style={{ display: 'flex', justifyContent: "center", marginTop: '9px' }}>
                                <Link to='/level_report'>
                                    <div className="text-center	fas fa-angle-double-right" style={{ color: '#009dff', border: '1px solid #c2c0c0', borderRadius: '25px', padding: '3px' }}></div>
                                </Link>
                            </div>

                        </div>
                    </div>
                    <div className="col-6 col-md-6 mb-3 dashboard_col_4">
                        <div className="portfolio-income-card">
                            <img src={RewardIncomeImg} alt="Matching Income" className="portfolio-icon" />
                            <div className="dashboard_heading">REWARD INCOME</div>
                            <p>${rewardIncome}</p>
                            <div style={{ display: 'flex', justifyContent: "center", marginTop: '9px' }}>
                                <Link to='/reward_report'>
                                    <div className="text-center	fas fa-angle-double-right" style={{ color: '#009dff', border: '1px solid #c2c0c0', borderRadius: '25px', padding: '3px' }}></div>
                                </Link>
                            </div>

                        </div>
                    </div>
                    <div className="col-12 col-md-12 mb-3 dashboard_col_4">
                        <div className="portfolio-income-card" style={{ textAlign: 'left' }}>
                            <div className='d-flex justify-content-evenly'>
                                <img src={AvailableForWithdrawalImg} alt="Direct Income" className="portfolio-icon" style={{ width: '50px', height: '50px' }} />
                                <div className='' style={{textAlign:'center',alignContent:'center'}}>
                                    <div className="dashboard_heading">AVAILABLE WITHDRAWAL BALANCE</div>
                                    <p className='mb-0' style={{ color: 'green',fontSize:'20px' }}>${availableWithdrawalIncome}</p>
                                </div>
                            </div>
                            <div className='d-flex justify-content-end' style={{ marginRight: '9px' }}>

                                <div style={{ marginTop: '5px' }}>
                                    <Link to='/unstake'>
                                        <div className="text-center	fas fa-angle-double-right" style={{ color: '#009dff', border: '1px solid #c2c0c0', borderRadius: '25px', padding: '3px' }}></div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-6 col-md-6 mb-3 dashboard_col_4">
                        <div className="portfolio-income-card">
                            <img src={DirectIncomeImg} alt="Level Income" className="portfolio-icon" />
                            <div className="dashboard_heading">EARNING LIMIT</div>
                            <p>${earningWalletIncome}</p>
                            <div style={{ display: 'flex', justifyContent: "center", marginTop: '9px' }}>
                                <Link to='#'>
                                    <div className="text-center	fas fa-angle-double-right" style={{ color: '#c2c0c0', border: '1px solid #c2c0c0', borderRadius: '25px', padding: '3px' }}></div>
                                </Link>
                            </div>

                        </div>
                    </div>
                    <div className="col-6 col-md-6 mb-3 dashboard_col_4">
                        <div className="portfolio-income-card">
                            <img src={LevelIncomeImg} alt="Direct Income" className="portfolio-icon" />
                            <div className="dashboard_heading">TOTAL WITHDRAWAL</div>
                            <p>${totalWithdrawal}</p>
                            <div style={{ display: 'flex', justifyContent: "center", marginTop: '9px' }}>
                                <Link to='/withdraw_report'>
                                    <div className="text-center	fas fa-angle-double-right" style={{ color: '#009dff', border: '1px solid #c2c0c0', borderRadius: '25px', padding: '3px' }}></div>
                                </Link>
                            </div>

                        </div>
                    </div>
                    {/* <div className="col-6 col-md-6 mb-3 dashboard_col_4">
                        <div className="portfolio-income-card">
                            <img src={AvailableForWithdrawalImg} alt="Level Income" className="portfolio-icon" />
                            <div className="dashboard_heading">WITHDRAW BALANCE</div>
                            <p>${availableWithdrawalIncome}</p>
                            <div style={{ display: 'flex', justifyContent: "center", marginTop: '9px' }}>
                                <Link to='/unstake'>
                                    <div className="text-center	fas fa-angle-double-right" style={{ color: '#c2c0c0', border: '1px solid #c2c0c0', borderRadius: '25px', padding: '3px' }}></div>
                                </Link>
                            </div>

                        </div>
                    </div> */}

                </div>

                <div className="row dashboard_row ">

                    <div className="col-6 col-md-6 mb-3 dashboard_col_4">
                        <div className="portfolio-income-card">
                            <img src={StackingIncomeImg} alt="Level Income" className="portfolio-icon" />
                            <div className="dashboard_heading">TEAM STAKE BONUS</div>
                            <p>${stackingBonusIncome}</p>
                            <div style={{ display: 'flex', justifyContent: "center", marginTop: '9px' }}>
                                <div to='#' onClick={toggalreddempopup}>
                                    <div className="text-center" style={{ color: '#009dff', border: '1px solid #c2c0c0', borderRadius: '25px', padding: '3px 10px' }}>Transfer</div>
                                </div>
                            </div>

                        </div>
                    </div>
                    {redeembonus &&
                        (<>
                            <div className="wallet-popup-overlay">
                                <div className="wallet-popup">
                                    <div className="wallet_popup_heading" style={{ background: 'linear-gradient(229deg, rgb(31, 199, 212) -13.69%, rgb(118, 69, 217) 91.33%)' }}>
                                        <h5 style={{ color: 'white' }}>Transfer to withdrawal wallet</h5>
                                        <button className="close-popup-btn" style={{ margin: '0px -10px 0px 10px', padding: '0 10px', textAlign: 'end' }} onClick={closepopup}><i className='fas fa-close'></i></button>
                                    </div>
                                    <div className="wallet_popup_para">
                                        <p><strong>TEAM STAKE BOUNS</strong></p>

                                        <div className='d-flex'>
                                            <input
                                                type='text'
                                                name='wallet'
                                                value={stackingBonusIncome || ''}
                                                // onChange={handleWalletAddressChange}
                                                readOnly
                                            />
                                            <button className="close-popup-btn" onClick={handlestaketransfer} style={{ marginTop: '0px', marginLeft: '10px' }}>Transfer</button>
                                        </div>
                                        <div className='d-flex justify-content-evenly'>
                                            <Link className="close-popup-btn" to='/stakingtranfer_report'>Transferred</Link>
                                            <Link className="close-popup-btn" style={{width:'100px',textAlign:'center'}} to='/staking_report'>Received</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>)
                    }
                    <div className="col-6 col-md-6 mb-3 dashboard_col_4">
                        <div className="portfolio-income-card">
                            <img src={RedeemBonusImg} alt="Direct Income" className="portfolio-icon" />
                            <div className="dashboard_heading">TEAM REDEEM BOUNS</div>
                            <p>${redeemIncome}</p>
                            <div style={{ display: 'flex', justifyContent: "center", marginTop: '9px' }}>
                                <div to='#' onClick={toggalreddempopup1} >
                                    <div className="text-center" style={{ color: '#009dff', border: '1px solid #c2c0c0', borderRadius: '25px', padding: '3px 10px' }}>Transfer</div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <center>
                <div>© Phi Network Business Limited, 2024</div>
            </center>
            </div>
            {redeembonus1 &&
                (<>
                    <div className="wallet-popup-overlay">
                        <div className="wallet-popup">
                            <div className="wallet_popup_heading" style={{ background: 'linear-gradient(229deg, rgb(31, 199, 212) -13.69%, rgb(118, 69, 217) 91.33%)' }}>
                                <h5 style={{ color: 'white' }}>Transfer to withdrawal wallet</h5>
                                <button className="close-popup-btn" style={{ margin: '0px -10px 0px 10px', padding: '0 10px', textAlign: 'end' }} onClick={closepopup}><i className='fas fa-close'></i></button>
                            </div>
                            <div className="wallet_popup_para">
                                <p><strong>TEAM REDEEM BOUNS</strong></p>
                                <div className='d-flex'>
                                    <input
                                        type='text'
                                        name='wallet'
                                        value={redeemIncome || ''}
                                        // onChange={handleWalletAddressChange}
                                        readOnly
                                    />
                                    <button className="close-popup-btn" onClick={handleredeemtransfer} style={{ marginTop: '0px', marginLeft: '10px' }}>Transfer</button>
                                </div>
                                <div className='d-flex justify-content-evenly'>
                                    <Link className="close-popup-btn" to='/redeemtransfer_report'>Transferred</Link>
                                    <Link className="close-popup-btn" style={{width:'100px',textAlign:'center'}} to='/redeem_report'>Received</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </>)
            }

           

            <Footer />
            <ToastContainer />
        </>
    );
}

export default Dashboard;
