import axios from 'axios';
import React, { useState, useEffect, useContext, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import '../../assets/css/team.css';
import { WalletContext } from '../Common/WalletContext';
import logo from '../../assets/img/logo.png';
import LevelIncomeImg from '../../assets/img/level_income_img.png';

// import 'slick-carousel/slick/slick.css';
// import 'slick-carousel/slick/slick-theme.css';
// import Pagination from './react-pegination';
import Header from '../Common/Header';
import Footer from '../Common/Footer';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Team() {

  const { walletAddress, balance, isConnecting, connectWallet, disconnectWallet } = useContext(WalletContext);
  const navigate = useNavigate();


  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);


  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const apiImageUrl = process.env.REACT_APP_IMAGE_BASE_URL;

  const [recMetrics, setRecMetrics] = useState([0, 0, 0])

  const reports = []; // Your report data
  const perPage = 10; // Number of reports per page

  // Pagination
  const [pageNumber, setPageNumber] = useState(0);
  const pageCount = Math.ceil(reports.length / perPage);

  const handlePageClick = ({ selected }) => {
    setPageNumber(selected);
  };

  // Pagination logic to slice the reports array
  const offset = pageNumber * perPage;
  const currentPageReports = reports.slice(offset, offset + perPage);

  const checkWallet = async () => {
    if (!walletAddress) {
      navigate(-1);
      toast.error('Connect Your Wallet');
      return; // Return early if walletAddress is not available.
    }
  };
  const checkWalletAndFetchData = useCallback(async (walletAddress) => {
    if (!walletAddress) {
        toast.error('Wallet not connected. Waiting...');
        return;
    }

    try {
        const response = await axios.get(`${apiBaseUrl}/getuser/${walletAddress}`);
        const userData = response.data;
        const userID = userData?.id;

        if (!userID) {
            toast.error('User not found.');
            navigate('/register');
        } else {
            localStorage.setItem('user_ID', userID);
        }
    } catch (error) {
        toast.error(error.message);
    }
}, [apiBaseUrl, navigate]);

  useEffect(() => {
    if (walletAddress) {
      checkWalletAndFetchData(walletAddress);
    }
    fetchData();
  }, [pageNumber]);




  const fetchData = async () => {
    try {

      const id = localStorage.getItem('walletAddress');
      const response = await axios.get(`${apiBaseUrl}/userteamapi/${id}?page=${pageNumber + 1}`);
      if(response.data){
      setData(response.data);
    }else{
      navigate('/register');
      toast.error('User not register')
    }
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };


  const [modalMessage3, setModalMessage] = useState(null);
  useEffect(() => {
    // Replace icons after component is mounted
    const storedMessage = localStorage.getItem('modalMessage3');
    if (storedMessage) {
      setModalMessage(JSON.parse(storedMessage));
      // Remove modal message from local storage after retrieving
      localStorage.removeItem('modalMessage3');
    }





    fetchData();
  }, []);



  if (loading) {

    // <SkeletonLoader />

  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  // if(data == null)
  //   {
  //     return (
  //       <SkeletonLoader showHeader={false} showFooter={false} /> 

  //      )
  //   }

  const formatDate = (dateString) => {
    const months = [
      'Jan', 'Feb', 'March', 'April', 'May', 'June',
      'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'
    ];

    const date = new Date(dateString);
    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();

    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');

    return `${day} ${month} ${year} | ${hours}:${minutes}:${seconds}`;
  };


  return (


    <body className=''>
      <div >


        <Header />

        <section style={{marginTop:'65px'}}>
          <div >
            <div className="team-body-content">
              <center><h3 className='mt-2 mb-3 network_heading'>Direct Network</h3></center>
              <section>
                <div className='user_address shadow'>
                  <div>
                    <div>{data && data.user && data.user.sponcer_id}</div>
                    {/* <div>0x6ea894D4B80954990af0ef0f4efB3d9A20B0a750</div> */}

                  </div>
                </div>
                <p className='sponcer_para'>Sponcer</p>
              </section>
              <section>
                <div className='total_business shadow'>
                  <div>
                    <img src={LevelIncomeImg} alt="Direct Income" className="portfolio-icon" />
                  </div>
                  <div>
                    <h6 className='text-white'>Total Team Staking</h6>
                    <div className='text-white' style={{fontSize:'18px'}}><strong>${data && data.user && data.user.my_business}</strong>  </div>
                  </div>
                </div>
              </section>

              {data && data.user_team && data.user_team.data.length > 0 ? (
                <section className='mt-2'>

                  {data.user_team.data.map((user, index) => (
                    <div key={index} className="col-12">
                      <div className="team-transaction-box shadow">
                        <div>
                          <div>

                            <div className="team-transaction-name-2 mb-2">
                              {user.email}

                            </div>
                          </div>
                          <div className='d-flex justify-content-between'>
                            <div style={{ display: 'flex', alignItems: 'center' }}><span className="report-date" style={{fontSize:'12px',color:'#0d6efd'}}>{formatDate(user.joining_date)}</span><br /></div>
                            <div style={{ display: 'flex', alignItems: 'center' }}><h6 className='' style={{color:'#0d6efd'}}>${user.my_package}</h6></div>

                          </div>
                        </div>
                      </div>
                      {/* <span style={{color:'black'}}>{user.first_name.charAt(0).toUpperCase() + user.first_name.slice(1)}</span> */}

                      {/* <div className="team-transaction-box shadow">
                        <a href="#" style={{ textDecoration: 'none' }} >

                  
                          <div className="">
                            <div className="align-content-center">

                              <div className="team-transaction-name-2">
                                {user.email}

                              </div>
                              
                            </div>
                          </div>
                          <div className="align-content-center">
                            <span className="align-content-center report-date">{formatDate(user.joining_date)}</span><br />
                          </div>

                          <div className="align-content-center text-center">
                            <h3 className='transaction-btn2'>{user.is_active === 'active' ? 'Active' : 'Inactive'}</h3>

                          </div>

                        </a>
                      </div> */}
                    </div>
                  ))}

                </section>
              ) : (
                <div style={{ textAlign: 'center' }}>No Team data available</div>
              )}


            </div>
          </div>
        </section>
        <ToastContainer />
        <Footer />
      </div>
    </body>
  );
}

export default Team;
