
import React, { useState, useContext, useEffect } from 'react';

import Header from '../Views/Common/Header';
import Footer from '../Views/Common/Footer';
import SwapCSS from '../assets/css/Swap.css';
import Token from '../assets/img/token.png';
import Phi from '../assets/img/phi.png';
import USDT from '../assets/img/usdt.png';
import Help from '../assets/img/help.png';
import phiBlueLogo from '../assets/img/phi_blue_logo_1.png';
import phiNameLogoFull from '../assets/img/phi_name_logo.png';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { WalletContext } from './Common/WalletContext';
import { Link } from 'react-router-dom';
import Logo from '../assets/img/logo.png';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';

// Register Chart.js components
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);


const Swap = () => {
    const { walletAddress, connectWallet, balance, usdtbalance, mintPHI, phirate, BurnPHI } = useContext(WalletContext);

    const [selectedToken1, setSelectedToken1] = useState('PHI');
    const [selectedToken2, setSelectedToken2] = useState('USDT');
    const [Phiamount, setPHIAmount] = useState('');
    const [USDTamount, setUSDTAmount] = useState('');

    // Update PHI and USDT amounts based on rate
    const handlePhiChange = (e) => {
        const value = e.target.value;
        setPHIAmount(value);
        setUSDTAmount((value * phirate).toFixed(4));
    };

    const handleUsdtChange = (e) => {
        const value = e.target.value;
        setUSDTAmount(value);
        setPHIAmount((value / phirate).toFixed(4));
    };
    const copyToClipboard = () => {
        const address = process.env.REACT_APP_CONTRACT_ADDRESS;
        navigator.clipboard.writeText(address).then(() => {
            toast.success("Address copied!");
        }).catch((err) => {
            console.error('Failed to copy: ', err);
            toast.error('Failed to copy');
        });
    };

    // Tokens data with images
    const tokens = {
        PHI: require('../assets/img/logo.png'),  // PHI icon
        USDT: require('../assets/img/usdt.png') // USDT icon
    };

    // Handle changes in the first dropdown
    const handleToken1Change = (e) => {
        const newToken1 = e.target.value;
        const newToken2 = newToken1 === 'PHI' ? 'USDT' : 'PHI';
        setSelectedToken1(newToken1);
        setSelectedToken2(newToken2);
    };

    const handleSwapClick = () => {

        if (selectedToken1 == "PHI") {
            mintPHI(USDTamount); // Adjust recipient address and amount accordingly
        } else {
            BurnPHI(Phiamount);
        }
    };


    // Handle changes in the second dropdown
    const handleToken2Change = (e) => {
        const newToken2 = e.target.value;
        const newToken1 = newToken2 === 'PHI' ? 'USDT' : 'PHI';
        setSelectedToken2(newToken2);
        setSelectedToken1(newToken1);
    };

    const walletaddress = localStorage.getItem('walletAddress');

    const truncateToFiveDigits = (num) => {
        const numStr = num.toString();
        if (numStr.includes('.')) {
            const [integerPart, decimalPart] = numStr.split('.');
            return `${integerPart}.${decimalPart.slice(0, 5)}`;
        }
        return numStr; // If no decimal part, return the integer part as is
    };

    const handlePercentageClick = (percentage) => {
        const selectedBalance = selectedToken2 === 'PHI' ? balance : usdtbalance;
        const amount = selectedBalance * (percentage / 100); // No rounding

        if (selectedToken2 === 'PHI') {
            setPHIAmount(truncateToFiveDigits(amount));
            setUSDTAmount(truncateToFiveDigits(amount * phirate));
        } else {
            setUSDTAmount(truncateToFiveDigits(amount));
            setPHIAmount(truncateToFiveDigits(amount / phirate));
        }
    };

    const [phiPrices, setPhiPrices] = useState([]);

    // Calculate prices for the last 10 days
    useEffect(() => {
        const deploymentPrice = 0.16180;  // Price 10 days ago
        const currentPrice = phirate;     // Current live price
        const daysSinceDeployment = 10;   // Days since deployment
        const futureDays = 20;            // Number of future days to project

        const today = new Date();         // Current date
        const startDate = new Date(today);
        startDate.setDate(today.getDate() - daysSinceDeployment); // 10 days ago

        // Calculate the daily growth rate from deployment to now
        const dailyGrowthRate = Math.pow(currentPrice / deploymentPrice, 1 / daysSinceDeployment) - 1;

        const prices = [];
        let currentCalculatedPrice = deploymentPrice;

        // Helper function to format dates as "DD/MM/YYYY" or "MMM DD, YYYY"
        const formatDate = (date) => {
            const day = date.getDate().toString().padStart(2, '0'); // Add leading zero if needed
            const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-indexed
            const year = date.getFullYear();
            return `${day}/${month}/${year}`; // You can adjust this format as needed
        };

        // Historical prices (past 10 days)
        for (let i = 0; i < daysSinceDeployment; i++) {
            const date = new Date(startDate);
            date.setDate(startDate.getDate() + i); // Increment date by i days
            prices.push({
                day: formatDate(date),  // Use formatted date instead of just day number
                price: Number(currentCalculatedPrice.toFixed(5)),
                type: 'Historical'
            });
            currentCalculatedPrice *= (1 + dailyGrowthRate);
        }

        // Future projections
        // for (let i = 0; i < futureDays; i++) {
        //     const futureDate = new Date(today);
        //     futureDate.setDate(today.getDate() + i); // Increment future dates by i days
        //     prices.push({
        //         day: formatDate(futureDate),  // Use formatted date for future projections
        //         price: Number(currentCalculatedPrice.toFixed(5)),
        //         type: 'Projected'
        //     });
        //     currentCalculatedPrice *= (1 + dailyGrowthRate);
        // }


        setPhiPrices(prices);
    }, [phirate]);

    const data = {
        labels: ['1Yr', '1Month', '1Hrs', '1Wk', '1Day', '30Min', '1Min'],        
        datasets: [
            {
                // Construct the label dynamically with styling
                label: `PHI/USDT $${phirate}`,
                data: phiPrices.map(item => item.price), // Extracting only price values
                fill: false,
                backgroundColor: '#26a17b',
                borderColor: '#26a17b',
            },
        ],
    };
    
    const options = {
        scales: {
            x: {
                title: {
                    display: false,
                    text: 'Days',
                    font: {
                        size: 8, // Set font size to 12px for y axis
                    },
                },
                ticks: {
                    font: {
                        size: 10, // Adjust this value to make the day labels smaller
                    },
                },
                beginAtZero: true,
            },
            y: {
                title: {
                    display: true,
                    text: 'PHI LISTING PRICE $0.1618',
                    font: {
                        size: 8, // Set font size for y-axis title
                        weight: '1000', // Font weight for y-axis title
                    },
                },
                beginAtZero: true,
            },
        },
        plugins: {
            legend: {
                display: true,
                position: 'bottom',
                labels: {
                    font: {
                        size: 12, // Set font size for legend label
                        weight: '700', // Set font weight for legend label
                    },
                  
                },
            },
        },
    };


    return (
        <>
            <Header />
            <div style={{ backgroundColor: 'white', margin: '47px 0 0 0' }}>
                <ul
                    className="sab_nav_link"
                    style={{
                        margin: '0',
                        padding: '15px 0',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-evenly',
                        boxShadow: 'rgba(133, 133, 133, 0.1) 0px -2px 0px -8px inset',
                        // background: 'linear-gradient(170deg, #494949, #00bfff69)',
                        // overflowX: 'scroll',  // Enable horizontal scrolling
                        whiteSpace: 'nowrap',
                        listStyleType: 'none',
                        scrollbarWidth: 'none',  // For Firefox
                        msOverflowStyle: 'none'  // For Internet Explorer and Edge
                    }}
                >
                    <li style={{ padding: '0 10px', fontWeight: '600' }}>
                        <Link style={{ textDecoration: 'none', color: 'black', fontSize: '12px' }} to='/aboutUs'>
                            <img src={phiBlueLogo} style={{ width: '22px', margin: '0px 2px 2px 0px' }} alt="logo" />  PHI</Link>
                    </li>
                    <li style={{ padding: '0 10px', fontWeight: '600' }}>
                        <Link to='/' style={{ textDecoration: 'none', color: 'black', fontSize: '12px' }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 512 512" style={{ margin: '0px 2px 2px 0px' }}>
                                <path fill="#00bfff" d="M287.03 20c-39.133.48-79.73 15.297-117 45.938h80.47v43.188c52.76-29.75 114.592-31.588 163.938.03l-18.188 9.72l64.688 50.72l-3.75-83.314l-26.407 14.126C395.99 48.792 345.038 20.644 290.907 20a162 162 0 0 0-3.875 0zm-268 64.625v212.75h212.782V84.625H19.032zm50.282 26.03H205.78v138.939h-18.718v-120.25H69.313v-18.688zm3.344 38.126l90.094 91.845l-13.344 13.094l-90.094-91.845zm206.656 61.75v212.782h212.75v-212.78h-212.75zm50.25 26.064h136.469V375.5h-18.686V255.28h-117.78l-.002-18.686zm3.344 38.094l90.125 91.875l-13.342 13.062l-90.125-91.844l13.343-13.092zm-278.53 63.656l3.75 83.312l23.312-12.47c60.927 88.637 169.99 106.485 259.625 32.814h-80.439v-43.188c-52.08 29.38-113 31.544-162.03 1.188l20.436-10.938z" />
                            </svg>
                            {' '}Trade</Link>
                    </li>
                    <li style={{ padding: '0 10px', fontWeight: '600', fontSize: '12px' }}>
                        <a href='https://bscscan.com/token/0xAD4B7c03DC0414Ee94c7D3956b2355c71c94490C' style={{ textDecoration: 'none', color: 'black' }} target='_blank'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 16 16" style={{ margin: '0px 2px 2px 0px' }}>
                                <g fill="#00bfff">
                                    <path d="M5.5 9.511c.076.954.83 1.697 2.182 1.785V12h.6v-.709c1.4-.098 2.218-.846 2.218-1.932c0-.987-.626-1.496-1.745-1.76l-.473-.112V5.57c.6.068.982.396 1.074.85h1.052c-.076-.919-.864-1.638-2.126-1.716V4h-.6v.719c-1.195.117-2.01.836-2.01 1.853c0 .9.606 1.472 1.613 1.707l.397.098v2.034c-.615-.093-1.022-.43-1.114-.9zm2.177-2.166c-.59-.137-.91-.416-.91-.836c0-.47.345-.822.915-.925v1.76h-.005zm.692 1.193c.717.166 1.048.435 1.048.91c0 .542-.412.914-1.135.982V8.518z" />
                                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                                    <path d="M8 13.5a5.5 5.5 0 1 1 0-11a5.5 5.5 0 0 1 0 11m0 .5A6 6 0 1 0 8 2a6 6 0 0 0 0 12" />
                                </g>
                            </svg>
                            {' '}Supply
                        </a>
                    </li>
                    <li style={{ padding: '0 10px', fontWeight: '600', fontSize: '12px' }}>
                        <a href='https://bscscan.com/address/0xAD4B7c03DC0414Ee94c7D3956b2355c71c94490C' style={{ textDecoration: 'none', color: 'black' }} target='_blank'>
                            {/* <img src={Phi} style={{ width: '22px', margin: '0px 2px 2px 0px' }} alt="logo" />  */}
                            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24" style={{ margin: '0px 2px 2px 0px' }}>
                                <g fill="none" stroke="#00bfff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" color="#52bfff">
                                    <path d="M21.5 11.029c.324.774.5 1.605.5 2.471c0 2.059-.995 3.924-2.607 5.279a1.07 1.07 0 0 0-.393.813V22h-2l-.794-1.133a.52.52 0 0 0-.552-.198a10.7 10.7 0 0 1-5.308 0a.52.52 0 0 0-.552.198L9 22H7v-2.384a1 1 0 0 0-.432-.824C5.493 18.052 2 16.602 2 15.058V13.5c0-.592.448-1.071 1-1.071c.607 0 1.102-.236 1.302-.863c.767-2.402 2.929-4.33 5.719-5.145" />
                                    <path d="M14.125 9.5v-6M16 3.5V2m0 9V9.5m-1.875-3h3.75m0 0c.621 0 1.125.504 1.125 1.125v.75c0 .621-.504 1.125-1.125 1.125H13m4.875-3C18.496 6.5 19 5.996 19 5.375v-.75c0-.621-.504-1.125-1.125-1.125H13M7.5 12h.009" />
                                </g>
                            </svg>
                            {' '}Liquidity
                        </a>
                    </li>
                </ul>
            </div>

            <style jsx>{`
    .nav-links::-webkit-scrollbar {
        display: none;  // For Chrome, Safari, and Opera
    }
`}</style>

            {/* <div style={{ backgroundColor: 'white', margin: '0' }}>
                <ul className="nav-links sab_nav_link" style={{ margin: '0', padding: '15px', alignItems: 'center', justifyContent: 'center', boxShadow: 'rgba(133, 133, 133, 0.1) 0px -2px 0px -8px inset' }}>
                    <li>Swap</li>
                    <li>Liquidity</li>
                    <li>Perpetual</li>
                    <li>options</li>
                    <li>Bridge</li>
                    <li>Trending Reward</li>
                </ul>
            </div > */}
            <div className="toggle-switch">

                <div className='tab d-flex justify-content-between'><span><img src={phiNameLogoFull} style={{ width: '49px', margin: '0px 2px 2px 20px' }} alt="logo" /></span><span style={{ color: '#26a17b', marginRight: '20px' }}> ${phirate}</span> </div>

                {/* <button
                    className={`tab ${activeTab === "MARKET" ? "active" : ""}`}
                    onClick={() => handleTabClick("MARKET")}
                >
                    MARKET
                </button>
                <button
                    className={`tab ${activeTab === "TWAP" ? "active" : ""}`}
                    onClick={() => handleTabClick("TWAP")}
                >
                    TWAP
                </button>
                <button
                    className={`tab ${activeTab === "LIMIT" ? "active" : ""}`}
                    onClick={() => handleTabClick("LIMIT")}
                >
                    LIMIT
                </button> */}
            </div>
            <div className="swap-container">
                <div className="swap-card">
                    <div className="swap-header">
                        <h2>Swap</h2>
                        <p>Trade PHI in an instant</p>
                        <div className="icons">
                            <i className="fas fa-coins"></i>
                            <i className="fas fa-cog"></i>
                            <i className="fas fa-history"></i>
                        </div>

                    </div>
                    <hr style={{ margin: "0 !important", padding: "0 !important" }} />
                    <div className="swap-body">
                        <div>
                            <label className="token-label">
                                <div className='d-flex' style={{ justifyContent: 'center', alignItems: 'center' }}>
                                    <img src={tokens[selectedToken2]} alt={selectedToken2} className="token-icon" />
                                    <select value={selectedToken2} onChange={handleToken2Change} className="token-select" style={{ fontWeight: 'bolder' }}>
                                        <option value="PHI" >PHI</option>
                                        <option value="USDT" >USDT</option>
                                    </select>
                                    <div style={{ marginLeft: selectedToken2 === 'PHI' ? '-10px' : '0' }}><i class="fa-solid fa-caret-down"></i></div>
                                </div>
                                <div>
                                    {isNaN(parseFloat(selectedToken2 === 'PHI' ? balance : usdtbalance))
                                        ? "0.00"
                                        : selectedToken2 === 'PHI' ? balance : usdtbalance}
                                </div>                            </label>
                            <div className="swap-input">


                                <div className='mb-2'>
                                    {selectedToken2 === 'PHI' ? <input type="number" name='phi' defaultValue={Phiamount} onChange={handlePhiChange} placeholder="0.0" /> : <input type="number" name='usdt' defaultValue={USDTamount} onChange={handleUsdtChange} placeholder="0.0" />}
                                </div>
                                <div className='d-flex justify-content-between'>
                                    <div className='percentage-selection' onClick={() => handlePercentageClick(25)}>25%</div>
                                    <div className='percentage-selection' onClick={() => handlePercentageClick(50)}>50%</div>
                                    <div className='percentage-selection' onClick={() => handlePercentageClick(75)}>75%</div>
                                    <div className='percentage-selection' onClick={() => handlePercentageClick(100)}>Max</div>
                                </div>
                            </div>
                        </div>
                        <div className="swap-arrow" >
                            <i class="fa-solid fa-arrows-up-down" onClick={() => {
                                const tempToken = selectedToken1;
                                setSelectedToken1(selectedToken2);
                                setSelectedToken2(tempToken);
                            }}>
                            </i>
                        </div>
                        <div>
                            <label className="token-label">
                                <div className='d-flex' style={{ justifyContent: 'center', alignItems: 'center' }}>
                                    <img src={tokens[selectedToken1]} alt={selectedToken1} className="token-icon" />
                                    <select value={selectedToken1} onChange={handleToken1Change} className="token-select" style={{ fontWeight: 'bolder' }}>
                                        <option value="PHI">PHI</option>
                                        <option value="USDT">USDT</option>
                                    </select>
                                    <div style={{ marginLeft: selectedToken1 === 'PHI' ? '-10px' : '0' }}><i class="fa-solid fa-caret-down"></i></div>
                                </div>
                                <div>
                                    {isNaN(parseFloat(selectedToken1 === 'PHI' ? balance : usdtbalance))
                                        ? "0.00"
                                        : selectedToken1 === 'PHI' ? balance : usdtbalance}
                                </div>

                            </label>

                            <div className="swap-input">


                                <div >
                                    {selectedToken1 === 'PHI' ? <input type="number" name='phi' defaultValue={Phiamount} onChange={handlePhiChange} placeholder="0.0" /> : <input type="number" name='usdt' defaultValue={USDTamount} onChange={handleUsdtChange} placeholder="0.0" />}
                                </div>
                                {/* <div className='d-flex justify-content-between'>
                                    <div className='percentage-selection' onClick={() => handlePercentageClick(25)}>25%</div>
                                    <div className='percentage-selection' onClick={() => handlePercentageClick(50)}>50%</div>
                                    <div className='percentage-selection' onClick={() => handlePercentageClick(75)}>75%</div>
                                    <div className='percentage-selection' onClick={() => handlePercentageClick(100)}>Max</div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                    {/* <div className='text-end'>1 PHI = {phirate} USDT</div> */}

                    {/* <div className="slippage">
                        <span>Slippage Tolerance <i className="fa" style={{ color: '#1fc7d4' }}>&#xf040;</i></span>
                        <span className="percentage">0.5%</span>
                    </div> */}
                    {walletaddress ? <button className="connect-wallet-btn" onClick={handleSwapClick}>Swap</button> : <button className="connect-wallet-btn" onClick={connectWallet}>Connect Wallet</button>}

                </div>
            </div>
            <div className="footer-section" >
                <div className="container d-flex justify-content-between align-items-center" id='footer_mobile_section'>
                    <a href="https://www.bnbchain.org" className="footer-link" target="_blank" rel="noopener noreferrer">
                        Bridge assets to BNB Chain <i className="fas fa-external-link-alt"></i>
                    </a>
                    <div className="help-section">
                        <h4 style={{ color: 'black', fontWeight: '600' }}>Contract Address</h4>
                        <p style={{ fontSize: '14px', backgroundColor: '#eef9ff', border: '1px solid white', padding: '5px', borderRadius: ' 7px', color: 'black' }}> {process.env.REACT_APP_CONTRACT_ADDRESS}</p>
                        <button className="btn shadow need-help-btn" id='need-help-btns' onClick={copyToClipboard}>Copy</button>
                        {/* <img src={Help} alt="Bunny" className="bunny-img" /> */}
                    </div>
                </div>
            </div>
            <div className="graph-container" style={{ margin: '0px 10px 65px 10px' }}>
                <div className="toggle-switch">

                    <div className='tab d-flex justify-content-between'><span><img src={phiNameLogoFull} style={{ width: '49px', margin: '0px 2px 2px 20px' }} alt="logo" /></span><span style={{ color: '#26a17b', marginRight: '20px' }}> ${phirate}</span> </div>

                </div>
                <center className='shadow' style={{ backgroundColor: '#fff', padding: '10px 0', borderRadius: '15px', border: '1px solid #06c0ce' }}>
                    <Line data={data} options={options} />
                </center>
            </div>
            <Footer />
            <ToastContainer />
        </>
    );
}

export default Swap;
